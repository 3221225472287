import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';
import loadable from '@loadable/component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSackDollar } from '@fortawesome/pro-light-svg-icons';

import useStyles from './styles';

const Paper = loadable(() => import('@material-ui/core/Paper'));
const Grid = loadable(() => import('@material-ui/core/Grid'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const Hidden = loadable(() => import('@material-ui/core/Hidden'));

const RegistrationTryForFree = ({ sub = '' }) => {
  const styles = useStyles();

  return (
          <Paper elevation={4}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              >
                <Grid item lg={8} md={10} sm={10} xs={12} >
                  <Typography
                    variant="h4"
                    component="h2"
                    className={classnames(styles.headerGreen, styles.fourTopBotMargin)}
                    align="center">
                    Try For Free
                    <FontAwesomeIcon icon={faSackDollar} size="lg" className={styles.headerIcon}/>
                    <Typography
                      variant="caption"
                      component="p"
                      align="right"
                      className={styles.headerSubText}>
                       Try Total Loyalty with all features enabled for free
                    </Typography>
                  </Typography>
                </Grid>

                <Grid item sm={10} xs={12}>
                  <Hidden xsDown>
                    <Typography
                      variant="h6"
                      component="p"
                      align="center"
                      className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                      {'Build a Loyalty Card with the full functionality of Total Loyalty'}
                    </Typography>
                  </Hidden>
                  <Hidden smUp>
                    <Typography
                      variant="h6"
                      component="p"
                      align="center"
                      className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                      {'Build a Loyalty Card with the full'} <br/> {'functionality of Total Loyalty'}
                    </Typography>
                  </Hidden>
                </Grid>

                <Grid item lg={8} md={10} sm={10} xs={12}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    align="justify"
                    className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                    {`We believe Total Loyalty is the ideal Loyalty Card solution for any business but want you to be sure
                    Total Loyalty is the right product to help your business build better customer loyalty. Sign up to Total Loyalty and build a pass and share it with all the functionality Total Loyalty offers.`}
                  </Typography>
                </Grid>

                <Grid item lg={8} md={10} sm={10} xs={12} className={styles.fourBotMargin}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    align="justify"
                    className={classnames(styles.bodyNoBottomMargin, styles.fourBotMargin)}>
                    {`Build a Loyalty Card and share it with an Android device for Google Pay, and a iOS device for
                      Apple Wallet.  We are always adding new functionality and every subscription and our free tier has full access
                      to all features.`}
                  </Typography>
                </Grid>
            </Grid>
          </Paper>
  );
};

RegistrationTryForFree.propTypes = {
  sub: string,
};

export default RegistrationTryForFree;
